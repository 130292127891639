@import "../../themes/index.scss";

#login {
    .login.main-content {
        width: 100vw;
        height: 100vh;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $dark-blue;
        text-align: center;
    }

    .login.inner-wrapper {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $main-font;
        font-weight: $main-medium;
        color: $white;
        font-size: 18px;
        line-height: 140%;
    }

    .login-button {
        button {
            background-color: $teal;
            font-size: rem-calc(18);
            font-weight: $main-demi;
            color: $white;
            line-height: 1.5;
            padding: 8px 35px;
            border: none;
            border-radius: 3px;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -o-flex;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: background-color 0.3s;
    
            &:hover,
            &:focus {
                background-color: lighten($teal, 10%);
            }
        }
    }
}