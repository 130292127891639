@import "../../themes/index.scss";

.export-csv-btn {
  background-color: #46c2d2;
  font-size: rem-calc(14);
  font-weight: $main-bold;
  color: $white;
  padding: 6px 10px;
  margin: 25px 0;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;

  span {
    margin-right: 4px;
    display: inline-block;
  }

  &:hover,
  &:focus {
    background-color: $dark-blue;
  }
}
