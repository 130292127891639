// Loading spinner and animation
.loading-spinner {
  margin: 100px auto;
  width: 50px;
  height: 57px;
  text-align: center;
  font-size: 10px;
}

.loading-spinner > div {
  background-color: #46c2d2;
  height: 100%;
  width: 6px;
  margin: 0 1px;
  display: inline-block;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loading-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loading-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.loading-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loading-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
