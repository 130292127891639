@import "../../themes/index.scss";

.title-block {
  font-family: $main-font;
  font-weight: $main-bold;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h3.title {
    font-size: rem-calc(16);
    color: $dark-blue;
    margin: 0 5px 0 0;
    cursor: pointer;
    font-weight: 600;

    &:hover,
    &:focus {
      color: $teal;
    }
  }

  .title-tooltip-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.title-block-options {
  display: flex;
  align-items: center;

  > * {
    margin-left: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.chart-title-info {
  cursor: pointer;
  color: $teal;
}
