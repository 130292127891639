/* raleway-regular - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/raleway-v14-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Raleway'), local('Raleway-Regular'),
        url('/fonts/raleway-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/raleway-v14-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/raleway-v14-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/raleway-v14-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/raleway-v14-latin-regular.svg#Raleway') format('svg');
    /* Legacy iOS */
}

/* raleway-700 - latin */
@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/raleway-v14-latin-700.eot');
    /* IE9 Compat Modes */
    src: local('Raleway Bold'), local('Raleway-Bold'),
        url('/fonts/raleway-v14-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('/fonts/raleway-v14-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('/fonts/raleway-v14-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('/fonts/raleway-v14-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('/fonts/raleway-v14-latin-700.svg#Raleway') format('svg');
    /* Legacy iOS */
}

/* maison-neue-500 - medium */
@font-face {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/MaisonNeue-Medium.ttf');
}

/* maison-neue-600 - demi */
@font-face {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/MaisonNeue-Demi.ttf');
}

/* maison-neue-700 - bold */
@font-face {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/MaisonNeue-Bold.ttf');
}

/* maison-neue-300 - light */
@font-face {
    font-family: 'Maison Neue';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/MaisonNeue-Light.ttf');
}