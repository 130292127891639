.customer-notifications-page {
  .notifications-chart-wrapper select {
    width: 300px;
    border: 0;
    background-color: white;
    margin-right: 200px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 5px;
    padding: 10px;
    height: 40px;
    border-radius: 2px;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .filter-submit {
    padding: 4px;
    width: 70px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    height: 40px;
    background-color: #46c2d2;
    font-size: 0.875rem;
    font-weight: 700;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 2px;
    border: 0;
    flex-shrink: 0;
    align-self: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .notification-status-container label,
  .filter-fields-container label {
    display: block;
  }

  .notification-status-container,
  .filter-fields-container {
    display: block;
  }

  .filter-fields-container .filter-input {
    border: 0;
    margin-top: 5px;
    padding: 10px;
    margin-right: 10px;
    width: 300px;
    font-weight: bold;
    height: 40px;
    border-radius: 2px;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
  }

  .filter-form {
    display: flex;
  }

  .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .dashboard-item-2 {
    height: 100%;
  }

  .dashboard-inner {
    margin-bottom: 30px;
  }

  .paging-btn {
    height: 40px;
    background-color: #46c2d2;
    font-size: 0.875rem;
    font-weight: 700;
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 2px;
    border: 0;
    flex-shrink: 0;
    align-self: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 30%;
    max-width: 200px;
    margin: 40px 80px 0;
  }

  .paging-btn:disabled {
    cursor: initial;
    filter: brightness(80%);
  }

  .paging-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
