@import '../../themes/index.scss';

.select-site {
  min-width: 300px;
  margin: 8px;
}
.select-site__dropdown-indicator path,
.select-site__clear-indicator path {
	fill : rgba(255,255,255,.3);
}

.select-site__multi-value__remove path {
	fill: #176387
}