.change-password {
  color:#686b6d;
  margin: 16px;

  &__button {
    margin-top: 16px;
  }

  &__message {
    margin-top: 16px;
  }
}