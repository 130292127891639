.line-chart-container-outer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.line-chart-container {
  font-size: 1rem;
  height: 90%;
  padding: 10px;
  .x-axis {
    text {
      font-size: 28px;
    }
  }

  .y-axis {
    text {
      font-size: 28px;
    }
  }

  .tooltip-group {
    & line {
      opacity: 0;
    }
    &:hover line {
      opacity: 1;
    }

    & circle {
      fill: greenyellow;
      opacity: 0;
    }

    &:hover circle {
      opacity: 1;
    }
  }
}

svg.line-chart-viz {
  width: 100%;
  height: 100%;
  display: inherit;
  margin: 0 auto;
  overflow: visible;
}

.hover-point:hover {
  fill: greenyellow;
}
