@import "../../themes/index.scss";


input.stacked-checkbox {
  background-color: $filter-select-background;
  border: $filter-select-border;
  color: $filter-select-text-color;
  margin-right: 6px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  cursor: pointer;
}



label.stacked-label {
  font-size: rem-calc(12);
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-right: 150px;
}
