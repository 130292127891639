@import "../../themes/index.scss";

.reports-wrapper {
  max-width: 1060px;
  padding: 20px;
  color: $gray;

  h1 {
    color: $dark-blue;
  }
}

.report-item-stats {
  min-width: 100%;
  font-size: rem-calc(14);
  font-family: $alt-font;
  overflow-x: auto;

  table {
    border-collapse: collapse;
  }

  td,
  th {
    background-clip: padding-box;
    padding: 10px;
    border-bottom: solid 1px $light-gray;
    white-space: nowrap;
    position: relative;
    z-index: 50;
  }

  thead th {
    font-weight: $alt-bold;
    color: $dark-blue;
    text-align: left;
    border-bottom: solid 2px $dark-blue;
  }

  tr > td:first-child {
    background-color: $white;
    font-weight: $alt-bold;
    white-space: nowrap;
    position: sticky;
    left: 0;
    z-index: 100;
  }

  tr > td:nth-child(even) {
    background-color: $light-blue;
  }

  .sortable-column {
    cursor: pointer;
  }
}

.date-range-form {
  form {
    background-color: #eef7fd;
    padding: 10px 20px 20px;
    display: inline-flex;
    align-items: center;
  }

  label {
    max-width: 160px;
    width: 100%;
    font-size: rem-calc(14);
    font-weight: $main-bold;

    input {
      width: 100%;
      height: 40px;
      padding: 10px;
      border-radius: 2px;
      border: 0;
      box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .label {
      margin-bottom: 3px;
      display: inline-block;
    }
  }

  button {
    height: 40px;
    background-color: #46c2d2;
    font-size: rem-calc(14);
    font-weight: $main-bold;
    color: $white;
    padding: 10px 15px;
    border-radius: 2px;
    border: 0;
    margin-left: 30px;
    flex-shrink: 0;
    align-self: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: $dark-blue;
    }
  }

  button[disabled] {
    background-color: $gray;
    cursor: not-allowed;
  }

  .text-separator {
    font-weight: $main-bold;
    color: $dark-blue;
    padding: 10px 10px 0;
  }
}

.date-range-form-note {
  font-family: $alt-font;
  font-size: rem-calc(12);
  font-style: italic;
  color: #686b6d;
  margin-top: 4px;
  margin-bottom: 40px;
}

// Export button
.export-button {
  background-color: #46c2d2;
  font-size: rem-calc(14);
  font-weight: $main-bold;
  color: $white;
  padding: 6px 10px;
  margin: 25px 0;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  align-items: center;

  span {
    margin-right: 4px;
    display: inline-block;
  }

  &:hover,
  &:focus {
    background-color: $dark-blue;
  }
}

.export-report-options {
  .export-button {
    margin-bottom: 5px;
  }
}

.export-report-options-note {
  font-size: rem-calc(12);
  font-style: italic;
  margin-top: 0;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
