@import '../../themes/index.scss';

.creating-text {
  color: $dark-blue;
}

.error-text {
  color: $negative-value-color;
}

.text {
  color: $dark-blue;
}

label {
  color: $dark-blue;
}

#user-form-container {
  margin-top: 15px;

  h1, h2 {
    color: #406982;
  }
}

#user-form {
  margin-top: 15px;
}