.changelog-page-container {
  h1 {
    color: black;
  }
  .changelog-version-container {
    color: black;
    width: 100%;
    margin: auto;

    .changelog-section-title {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
