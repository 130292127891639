@import '../../themes/index.scss';

.interval-tooltip-container {
  background-color: rgba(31, 51, 64, 0.95);
  font-family: $alt-font;
  font-size: rem-calc(12);
  color: $white;
  padding: 5px 10px 10px;
  position: relative;
  z-index: 100;
  .date {
    font-size: rem-calc(11);
    font-weight: $alt-bold;
    color: $teal;
    line-height: 1.5;
    margin-bottom: 3px;
    display: block;
  }

  .text {
    margin-right: 8px;
    color: $text-color;
  }

  .value {
    margin-right: 8px;
    color: $text-color;
  }

  .change {
    color: $text-color;

    &.positive {
      color: #2ece2e;
      &:before {
        content: "\2191";
      }
    }

    &.negative {
      color: #ff5151;
      &:before {
        content: "\2193";
      }
    }
  }
}



