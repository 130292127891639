@import "../../themes/index.scss";

button.filter-select {
  font-size: rem-calc(12);
  font-weight: $main-demi;
  color: $white;
  background-color: $teal;
  height: 28px;
}

.filter-select-modal {
  .modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0rem 1rem 0.4rem 1rem;
  }

  .title-block {
    font-family: $main-font;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h3.title {
      font-size: rem-calc(16);
      color: $teal;
      margin: 0px;
      cursor: pointer;
    }
  }

  .modal-body {
    margin-top: 10px;

    .form-check {
      margin-bottom: 10px;
      label {
        input {
          margin-right: 10px;
        }
      }
    }
  }

  button.close {
    border: 0;
    background-color: #ffffff;
    font-size: rem-calc(36);
    cursor: pointer;
    line-height: 0.5;
  }

  .modal-footer {
    button {
      margin-right: 20px;
    }
  }
}

.filter-select-container {
  .arrow-down {
    width: 0;
    height: 0;
    margin-top: 3px;
    margin-left: 10px;
    border-style: solid;
    border-width: 9px 8px 0 8px;
    border-color: $white transparent transparent transparent;
    display: inline-block;
    transition: color 0.3s, transform 0.3s;
  }
}
