@import "../../themes/index.scss";

.dashboard-sidebar {
  height: 100%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar-logo {
  text-align: center;
  padding: 0 $sidebar-padding 96px $sidebar-padding;
  cursor: pointer;
  svg {
    max-width: 100%;
    margin: auto;
  }
}

.sidebar-item-content {
  font-size: 21px;
  font-family: $main-font;
  font-weight: $main-bold;
  color: $dark-blue;
  padding: 16px $sidebar-padding;
  line-height: 1;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: color .3s;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  position: relative;
  &:hover,
  &:focus {
    color: $teal;
  }

  &.selected {
    background-color: $white;
    // &::after {
    //   content: '';
    //   width: 4px;
    //   background: #FFFFFF;
    //   display: block;
    //   position: absolute;
    //   right: -4px;
    //   top: 0;
    //   bottom: 0;
    // }
  }

  .sidebar-item-title {
    white-space: nowrap;
  }
}

.sidebar-item-icon {
  width: 22px;
  color: $dark-blue;
  margin-right: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
}

span.sidebar-item-tag {
  font-size: rem-calc(12);
  color: $white;
  line-height: 1.17;
  padding: 4px 6px 3px 6px;
  display: inline-block;
  margin-left: 10px;

  &.teal {
    background-color: $teal;
  }
  &.red {
    background-color: $red;
  }

}

.sidebar-footer {
  font-size: rem-calc(14);
  font-family: $main-font;
  font-weight: $main-medium;
  color: $dark-blue;
  padding: $sidebar-padding;
  p {
    line-height: 150%;
    margin: 0;
  }
}

.sidebar-footer-callout {
  margin-bottom: 30px;
  color: $dark-blue;
  font-size: rem-calc(19);
  text-align: left;
  .info-title {
    font-size: rem-calc(23);
    font-weight: $main-bold;
    line-height: 150%;
  }
}