@import "./themes/index.scss";

// html {
//   font-size: 14px;
// }

// @media (max-width: 40em) {
//   html {
//     font-size: 13px;
//   }
// }

// @media (max-width: 60em) {
//   html {
//     font-size: 12px;
//   }
// }

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-attachment: fixed;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

#root {
  height: 100%;
  position: relative;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.margin-right-20 {
  margin-right: 20px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.nav-left {
  max-width: 650px;
  padding-right: 32px;
}

// TABLES

.table-container {
  overflow: scroll;
}

.table {
  width: 100%;
  font-family: $alt-font;
  border-collapse: collapse;

  tr {
    border-bottom: solid 1px $light-gray;
    text-align: left;
  }

  th {
    font-family: $main-font;
    color: $dark-blue;
    padding-top: 24px;
    padding-bottom: 10px;
    line-height: 1;
  }
  td {
    line-height: 1;
    padding: 10px 0;
  }
  tr th:last-child,
  tr td:last-child {
    text-align: right;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  th.sortable-column {
    cursor: pointer;
  }
}

// All widgets
.dashboard-widget {
  background-color: $white;
  height: 100%;
  width: 100%;
  font-family: $alt-font;
  color: $gray;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px $light-gray;
  overflow: auto;
  border-radius: $widget-border-radius;
  position: relative;
  &.error {
    .title {
      position: relative;
      display: flex;
      align-items: center;
      &::after {
        content: "!";
        background-color: #ff2600;
        width: 13px;
        height: 13px;
        font-size: 11px;
        color: $white;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: 7px;
      }
      &::before {
        content: "There was an error loading the most recent data";
        background-color: $white;
        min-width: 285px;
        font-size: rem-calc(12);
        font-weight: $main-medium;
        color: #ff2600;
        padding: 3px 3px 4px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        border-radius: 2px;
        line-height: 1;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        transition: opacity 0.3s;
        opacity: 0;
      }
    }
    .title:hover,
    .title:focus {
      &::before {
        opacity: 1;
      }
    }
  }

  &.loading {
    &::after {
      content: "Loading...";
      background-color: $light-gray !important;
      width: 100%;
      height: 100%;
      font-family: $main-font;
      font-weight: $main-bold;
      color: $dark-blue !important;
      text-transform: uppercase;
      opacity: 0.9 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

@mixin red-border {
  // border-color: red;
  // border-width: 2px;
  // border-style: solid;
}

.dashboard-container {
  color: $white;
  height: 100%;
  display: grid;
  grid-template-columns: 260px 1fr;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
}

.left-sidebar {
  background-color: $light-gray;
  width: 270px;
  padding-top: $sidebar-vertical-padding;
  padding-bottom:$sidebar-vertical-padding;
  flex-shrink: 0;
  position: relative;
  z-index: 50;
}

.main-content {
  width: 82vw;
  position: relative;
  z-index: 20;
}

.dashboard-outer {
  padding: $content-padding;
}

.inner-wrapper {
  color: $main-color;
  padding: $content-padding;
}

.highlight {
  color: $teal;
}

.white {
  color: #fff;
  fill: #fff;
}
