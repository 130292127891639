@import "../../themes/index.scss";

.custom-reports-wrapper {
  max-width: 1060px;
  padding: 20px;
  color: $gray;

  button {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  h1 {
    margin-top: 0px;
    font-family: $main-font;
    color: $dark-blue;
    font-size: 48px;
    margin-bottom: 5px;
  }

  input,
  label {
    display: block;
    font-size: rem-calc(14);
    font-weight: $main-bold;
  }

  input,
  select {
    width: 170px;
    border-color: black;
    background-color: white;
    margin-right: 20px;
    font-weight: bold;
  }

  .item-container,
  .value-container,
  .date-container {
    display: flex;
  }

  .item-container,
  .value-container,
  .group-by-container,
  .series-container,
  .go-button-container {
    margin-top: 20px;
  }

  .add-item-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-color: black;
    margin-right: 20px;
    margin-top: 16px;
  }

  .add-item-btn-text {
    font-size: 60px;
    height: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .start-date-error,
  .end-date-error,
  .item-categories-error {
    color: red;
    margin-right: 20px;
  }

  .item-categories-error {
    position: relative;
    top: -60px;
  }

  .item-listing-box {
    border-style: solid;
    border-width: 1px;
    width: 260px;
    min-height: 100px;
    margin-top: 16px;
    background: white;
  }

  .graph-box {
    margin-top: 0px;
  }

  .item-listing-box div {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .range-and-csv-container {
    display: flex;
    height: 24px;
    margin-bottom: 20px;
  }

  .range-and-csv-container div {
    margin-right: 80px;
  }

  .custom-report-graph-container button {
    margin-bottom: 20px;
  }

  .items-value-series-container {
    display: flex;
  }

  .value-and-series-container {
    margin-left: 102px;
  }

  .value-and-series-container div {
    margin-top: 20px;
  }

  .stacked-option-container {
    display: flex;
    margin-top: 40px;
  }

  .stacked-option-container input {
    width: 3%;
    margin-top: 2px;
    margin-left: 2px;
  }

  // Loading spinner and animation
  h1 > .loading-spinner {
    margin: initial;
    width: 50px;
    height: 34px;
    text-align: center;
    font-size: 10px;
    display: inline-block;
  }

  .line-chart {
    padding: 25px;
    &.loading {
      background-color: $light-gray;
      opacity: 0.9;
    }
  }

  .go-button-container button,
  .custom-report-graph-container button {
    height: 40px;
    background-color: #46c2d2;
    font-size: rem-calc(14);
    font-weight: $main-bold;
    color: $white;
    padding: 10px 15px;
    border-radius: 2px;
    border: 0;
    flex-shrink: 0;
    align-self: flex-end;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: $dark-blue;
    }
  }

  .go-button-container button[disabled],
  .custom-report-graph-container button[disabled] {
    background-color: $gray;
    cursor: not-allowed;
  }

  .custom-report-form-container {
    background-color: #eef7fd;
    padding: 10px 20px 20px;
  }
}
