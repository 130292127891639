.alerts-profile {
	background-color: #ffffff;
	color: #686b6d;
	padding: 40px;
	box-sizing: border-box;
	.inner {
		max-width: 925px;
	}
	button {
		cursor: pointer;
		transition: .3s background-color, .3s color;
		&.bare {
			background: none;
			font-weight: 700;
			border: 0;
			padding: 0;
			margin: 0;
		}
	}
	h2 {
		color: #406982;
		margin: 0 0 20px;
	}
	h3 {
		color: #46c2d2;
		margin: 0;
	}
	hr {
		background-color: #eef7fd;
		height: 4px;
		border: none;
	}
}

.alerts-profile * {
	box-sizing: border-box;
}

.alerts-profile-intro {
	margin-bottom: 30px;
}

.alerts-profile-users-name {
	display: flex;
	align-items: center;
	transition: color .3s;
	.arrow-down {
		width: 0;
		height: 0;
		margin-top: 3px;
		margin-left: 10px;
		border-style: solid;
		border-width: 9px 8px 0 8px;
		border-color: #46c2d2 transparent transparent transparent;
		display: inline-block;
		transition: color .3s, transform .3s;
	}
	&:hover, &:focus {
		color: #176387;
		cursor: pointer;
	}
	&.active-profile-link {
		color: #176387;
		.arrow-down {
			border-color: #176387 transparent transparent transparent;
			transform: rotate(180deg);
		}
	}
}

.alerts-profile-add {
	max-width: 450px;
	background-color: #eef7fd;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-size: 1.125rem;
	font-weight: 700;
	color: #686b6d;
	line-height: 1.5;
	border: 1px solid #dfe8ed;
	border-radius: 2px;
	padding: 25px 30px;
	margin-top: 40px;
	display: inline-flex;
	align-items: center;
	p {
		margin: 0 15px 0 0;
	}
	button {
		background-color: #46c2d2;
		font-size: 14px;
		font-weight: 700;
		color: #FFFFFF;
		line-height: 1;
		padding: 5px 10px;
		border: 0;
		border-radius: 2px;
		display: flex;
		align-items: center;
		cursor: pointer;
		.indicator {
			font-size: 14px;
			margin-right: 5px;
		}
		&:hover, &:focus {
			background-color: darken(#46c2d2, 10%);
		}
	}
}

.alerts-profile-users-name + .alerts-profile-form.active-profile {
	margin-top: 20px;
}

.alerts-profile-form {
	overflow: hidden;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
	transition: max-height .5s, margin .5s;
	.field {
		margin-bottom: 20px;
	}
	.field-full {
		grid-column: -1 / 1;
	}
	.field-frequency {
		grid-column: 2 / span 2;
	}
	label {
		span {
			font-size: 14px;
			font-weight: 700;
			color: #406982;
			line-height: 1.5;
			display: block;
		}
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"] {
		background-color: #ffffff;
		color: #686b6d;
		height: 40px;
		padding: 10px;
		border: 1px solid #cccccc;
		border-radius: 2px;
		box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.2);
	}
	button[type="submit"] {
		background-color: #f66e2c;
		font-size: 14px;
		font-weight: 700;
		color: #FFFFFF;
		line-height: 1.5;
		padding: 5px 10px;
		border-radius: 2px;
		border: 0;
		&:hover, &:focus {
			background-color: darken(#f66e2c,10%);
		}
		&.button-saving {
			background-color: gray;
			cursor: not-allowed;
		}
		&.button-saved {
			background-color: #379161;
			cursor: not-allowed;
		}
	}
	.checkbox-list {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.checkbox-wrapper {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		align-items: center;
		margin-right: 25px;
		margin-top: 5px;
		label {
			text-transform: capitalize;
			margin-left: 5px;
		}
	}
}

.alerts-profile-form .alerts-profile-users-actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 0;
	padding-bottom: 20px;
}

.alerts-profile-users-cancel-button {
	color: #52cddc;
	&:hover, &:focus {
		color: darken(#52cddc, 20%);
	}
}

.alerts-profile-users-delete-button {
	color: #c63030;
	&:hover, &:focus {
		color: darken(#c63030, 15%);
	}
}

.alerts-profile-users-button-group {
	span {
		font-weight: 700;
		margin: 0 5px 0 10px;
	}
}

.alerts-profile-users {
	padding: 10px 0;
	border-bottom: solid 1px #dfe8ed;
}