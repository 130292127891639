// .dashboard-outer {
//     width: 80vw;
//     height: 80vh;
// }

@mixin dashboard-item() {
  display: flex;
  padding: 0.15rem;
  flex: 1;
  max-height: 350px;
  box-sizing: border-box;
  padding: 0 20px 20px 0;
}

.dashboard-inner {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;

  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    height: 100%;
    flex-basis: 50%;
  }

  .dashboard-item {
    @include dashboard-item();

    &:last-child {
      padding-right: 0;
    }
  }

  .dashboard-item-2:last-child,
  .dashboard-item-3:last-child {
    padding-right: 0;
  }

  @media all and (min-width: 20em) {
    .dashboard-item {
      flex: 1 1 100%;
      min-width: 100%;
    }
  }

  @media all and (min-width: 40em) {
    .dashboard-item {
      flex: 1 1 50%;
      min-width: 50%;
    }
  }

  @media all and (min-width: 60em) {
    .dashboard-item {
      flex: 1 1 12.5%;
      min-width: 12.5%;
    }
  }

  @media all and (min-width: 75em) {
    .dashboard-item {
      flex: 1 1 12.5%;
      min-width: 12.5%;
      padding: 0 40px 40px 0;
    }
  }

  .dashboard-item-2 {
    @include dashboard-item();
  }

  @media all and (min-width: 20em) {
    .dashboard-item-2 {
      flex: 1 2 100%;
      min-width: 100%;
    }
  }

  @media all and (min-width: 40em) {
    .dashboard-item-2 {
      flex: 1 2 50%;
      min-width: 50%;
    }
  }

  @media all and (min-width: 60em) {
    .dashboard-item-2 {
      flex: 1 1 25%;
      min-width: 25%;
    }
  }

  @media all and (min-width: 75em) {
    .dashboard-item-2 {
      padding: 0 40px 40px 0;
    }
  }

  .dashboard-item-3 {
    @include dashboard-item();
  }

  @media all and (min-width: 20em) {
    .dashboard-item-3 {
      flex: 1 2 100%;
      min-width: 100%;
    }
  }

  @media all and (min-width: 40em) {
    .dashboard-item-3 {
      flex: 1 2 50%;
      min-width: 50%;
    }
  }

  @media all and (min-width: 60em) {
    .dashboard-item-3 {
      flex: 1 1 50%;
      min-width: 50%;
    }
  }

  @media all and (min-width: 75em) {
    .dashboard-item-3 {
      padding: 0 40px 40px 0;
    }
  }

  .stacked-outer {
    padding: 0em;

    .stacked-inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 20px;
      padding-right: 20px;
      .dashboard-stacked-item {
        flex: 1 1 50%;
      }
      .dashboard-item-2 {
        padding-right: 0;
        padding-bottom: 20px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 40em) {
    .stacked-outer {
      max-height: none;
    }
  }

  @media (min-width: 75em) {
    .stacked-outer .stacked-inner {
      padding-bottom: 40px;
      padding-right: 40px;
    }
  }

}

// .widget-grid-container {
//     position: relative;
//     height: 100%;
// }

// .bottom-info {
//     position: absolute;
//     bottom: 0px;
//     left: 4.1em;
//     right: 4.1em;
//     padding-bottom: 1em;
// }