.number-component-container {
  text-align: center;
  height: 100%;
  font-size: 1.3rem;

  .number-component-number {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
