@import "../../themes/index.scss";

.header {
  width: 100%;
  background: $white;
  color: $dark-blue;
  padding: 30px $content-padding;
  .select-site {
    margin: 0;
    z-index: 99;
  }
}

.login-button {
  button {
    background-color: $teal;
    font-size: rem-calc(18);
    font-weight: $main-demi;
    color: $white;
    line-height: 1.5;
    padding: 8px 35px;
    border: none;
    border-radius: 3px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover,
    &:focus {
      background-color: lighten($teal, 10%);
    }
  }
}

.header-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  width: 230px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 2px;
  border: 1px solid $light-gray;
  display: block;
  position: absolute;
  list-style: none;
  top: calc(100% + 10px);
  right: 0;
  z-index: 30;
}

.menu-inner {
  width: 100%;
  position: relative;
  // &::before {
  //   content: "";
  //   width: 20px;
  //   height: 20px;
  //   background-color: #ffffff;
  //   display: block;
  //   position: absolute;
  //   top: -20px;
  //   right: 10%;
  //   transform: rotate(45deg);
  // }
}

.menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
  button {
    background: none;
    font-size: rem-calc(16);
    font-family: $main-font;
    font-weight: $main-demi;
    color: $dark-blue;
    line-height: 1.5;
    border: none;
    transition: color 0.3s;
    cursor: pointer;
    &:hover,
    &:focus {
      color: $teal;
    }
  }

}

.menu-item {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}

.user-img {
  max-height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.user-avatar {
  margin-right: 10px;
}

.login-component {
  box-sizing: border-box;
  position: relative;

  .user-component {
    display: flex;
    justify-content: space-between;

    cursor: pointer;

    .username-outer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .username-inner {
      color: $teal;
      font-size: rem-calc(16);
      font-family: $main-font;
      font-weight: $main-demi;
      padding-bottom: 1px;
      border-bottom: solid 1px transparent;
      transition: border-color 0.3s;
      &:hover,
      &:focus {
        border-color: $white;
        color: lighten($teal, 10%);
      }
    }
  }
}

.select-site-label {
  color: #00263A;
  font-family: $main-font;
  font-weight: $main-demi;
  font-size: rem-calc(14);
  line-height: 1;
  display: inline-block;
  margin-bottom: 10px;
}
