svg.interval-chart-viz {
  width: 100%;
  height: 80%;
    .x-axis {
      text {
        font-size: 22px;
      }
    }

    .y-axis {
      text {
        font-size: 22px;
      }
    }
}

.current-data-path {
    fill: none;
    stroke: #666666;
    stroke-width: 5;
}

.past-data-path {
    stroke-dasharray: 5;
    fill: none;
    stroke: #666666;
    stroke-width: 5;
}

.interval-tooltip-group {
  & line {
    opacity: 0;
  }
  &:hover line {
    opacity: 1;
  }

  & circle {
    fill: greenyellow;
    opacity: 0;
  }

  &:hover circle {
    opacity: 1;
  }
}
