.table th,
.table tr {
  text-align: center !important;
  padding: 20px;
}

.column-legend-tooltip {
  position: relative;
  display: inline-block;
}

.column-legend-name {
  border-bottom: 1px dotted black;
}

.column-legend-tooltip .column-legend-tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 140%;
  top: 100%;
  left: 50%;
  margin-left: -60px;
}

.column-legend-tooltip:hover .column-legend-tooltiptext {
  visibility: visible;
}

.column-legend-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.monthly-kpi-page {
  width: 95%;
  margin: auto;
}

.table tr th:last-child,
.table tr td:last-child {
  text-align: center;
}

.filter-select-container {
  padding-bottom: 30px;
}

.filter-select {
  position: absolute;
  right: 20px;
}

.monthly-kpi-table .title {
  position: absolute;
  left: 20px;
  z-index: 50;
  top: 20px;
}

.monthly-kpi-container:not(.loading) > .monthly-kpi-table td:first-child,
.monthly-kpi-container:not(.loading) > .monthly-kpi-table th:first-child {
  position: sticky;
  left: -2px;
  z-index: 40;
  background-color: white;
}
