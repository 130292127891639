@import "../../themes/index.scss";

.single-container-component {
  height: inherit;

  .single-container-component-number {
    font-size: rem-calc(13);
    &.change {
      &.positive {
        color: green;
      }
      &.negative
      {
        color: red;
      }
    }
  }

  .title {
    font-size: rem-calc(14);
    margin: 10px 0 5px;
    cursor: pointer;
  }
}