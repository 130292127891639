@import "fonts";

// these were taken from variables in default theme, not sure if we need these?
// $background2: #1e5799;
/* Old browsers */
// $background1: linear-gradient(to right top, #cadfe6, #b6d4df, #a3c8da, #91bcd4, #7fb0cf);

// $widget-border-radius: 0px;
// $widget-color: rgba(0, 0, 0, 0.55);
// $widget-loading-color: rgba(0, 0, 0, 0.75);

// $header-text-color: #FFFFFF;

// Colors
$white: #ffffff;
$light-blue: #eef7fd;
$dark-blue: #00263A;
$teal: #00C1DE;
$gray: #686b6d;
$light-gray: #D8DFE1;
$red: #ED1C24;;


// Fonts
$main-font: Maison Neue, "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
  sans-serif;
$main-light: 300;
$main-medium: 500;
$main-demi: 600;
$main-bold: 700;
$main-color: $gray;

$alt-font: Arial, Helvetica, "Lucida Grande", sans-serif;
$alt-normal: 400;
$alt-bold: 700;
$alt-semibold: 600;

// Basic colors
$text-color: white;
$secondary-text-color: rgba(255, 255, 255, 0.5);
$background2: #7f2022;
$background1: linear-gradient(145deg, #c14750, #7f2022 40%, #650d0e);
$background-opacity: 0.5;

// Table
$table-border-color: rgba(255, 255, 255, 0.1);
$table-header-color: rgba(255, 255, 255, 0.5);

// Widget
$widget-color: rgba(0, 0, 0, 0.15);
$widget-loading-color: rgba(0, 0, 0, 0.25);
$widget-error-color: rgba(73, 14, 14, 0.8);
$widget-border-radius: 0px;

// Tooltip
$tooltip-background-color: rgba(0, 0, 0, 0.8);
$positive-value-color: green;
$negative-value-color: red;

// Header
$header-border-radius: 0px;
$header-text-color: $text-color;

// Filter Select
$filter-select-background: rgba(0, 0, 0, 0.5);
$filter-select-text-color: $text-color;
$filter-select-border: none;

// Spacing helpers
$sidebar-padding: 20px;
$sidebar-vertical-padding: 26px;
$content-padding: 40px;

// Get rem font size
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

//set $marginEnd to desired margin range. A mb-# and mt-# class will be generated every 5px between 0 and $marginEnd
$marginStart: 0;
$marginEnd: 50;

@while $marginStart <=$marginEnd {
  .margin-top-#{$marginStart} {
    margin-top: #{$marginStart}px !important;
  }

  .margin-bottom-#{$marginStart} {
    margin-bottom: #{$marginStart}px !important;
  }

  .margin-left-#{$marginStart} {
    margin-left: #{$marginStart}px !important;
  }

  .margin-right-#{$marginStart} {
    margin-right: #{$marginStart}px !important;
  }

  $marginStart: $marginStart + 5;
}
