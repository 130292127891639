@import './themes/index.scss';

html {
  height: 100%;
}

body {
  color: $main-color;
  margin: 0;
  padding: 0;
  font-family: $main-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  min-height: 100%;
  background: #FFFFFF;
  background: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}