@import '../../themes/index.scss';

.d3-tip {
  line-height: 1;
  background-color: rgba(31, 51, 64, 0.95);
  color: $text-color;
  z-index: 100;
}

.tooltip-container {
  font-family: $alt-font;
  font-size: rem-calc(11);
  padding: 5px 10px 10px;
}

.tooltip-row {
  width: 100%;
  display: flex;
  border-bottom: solid 1px $dark-blue;
  padding: 5px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
}

.tooltip-total-value {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-site-name {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}

.tooltip-date {
  font-weight: $alt-bold;
  color: $teal;
  line-height: 1.5;
  margin-bottom: 1px;
  display: block;
}

.tooltip-value {
  color: $text-color;
}